import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/Layout/layout";
import Container from "../components/Layout/Container";
import Seo from "../components/SEO/SEO";
import {
    ArticleButton,
    Chess,
    Columns,
    Contents,
    Counters,
    Embed,
    Features,
    Header,
    Image,
    List,
    Paragraph,
    Personality,
    Quote,
    Table,
} from "../components/Article/ArticleComponents";
import DynamicForm from "../components/Form/DynamicForm";
import TemplateString from "../components/Common/TemplateString";
import * as contentPage from "./content.module.css";

const articleComponents = {
    paragraph: Paragraph,
    header: Header,
    list: List,
    quote: Quote,
    image: Image,
    button: ArticleButton,
    contents: Contents,
    counters: Counters,
    embed: Embed,
    table: Table,
    personality: Personality,
    chess: Chess,
    columns: Columns,
    features: Features,
};

const secureParseJSON = (maybeJSON) => {
    try {
        return JSON.parse(maybeJSON);
    } catch (e) {
        console.error(e);

        return undefined;
    }
};

const CoursePageTemplate = ({pageContext: {pageData}}) => {
    const {title, description, content, form, seo} = pageData;

    const jsonContent = secureParseJSON(content);

    const haveTitleOrDescription = !!title || !!description;
    const haveAnyContent = haveTitleOrDescription || jsonContent;

    return (
        <Layout>
            <Seo seo={seo} />{" "}
            {haveAnyContent && (
                <Container className={contentPage.contentPage}>
                    {haveTitleOrDescription && (
                        <div className={contentPage.contentPage__hero}>
                            {!!title && (
                                <h1 className={contentPage.contentPage__title}>
                                    <TemplateString template={title} />
                                </h1>
                            )}{" "}
                            {!!description && <p className={contentPage.contentPage__desc}>{description}</p>}
                            <div>
                                <StaticImage
                                    src="../assets/images/content/gradient.png"
                                    alt=""
                                    layout="fixed"
                                    placeholder="none"
                                    loading="eager"
                                    className={contentPage.contentPage__gradient}
                                />
                                <StaticImage
                                    src="../assets/images/content/gradient_tablet.png"
                                    alt=""
                                    layout="fixed"
                                    placeholder="none"
                                    loading="eager"
                                    className={`${contentPage.contentPage__gradient} ${contentPage.contentPage__gradient_tablet}`}
                                />
                                <StaticImage
                                    src="../assets/images/content/gradient_mobile.png"
                                    alt=""
                                    layout="fixed"
                                    placeholder="none"
                                    loading="eager"
                                    className={`${contentPage.contentPage__gradient} ${contentPage.contentPage__gradient_mobile}`}
                                />
                            </div>
                        </div>
                    )}{" "}
                    {jsonContent && (
                        <article className={contentPage.contentPage__content}>
                            {jsonContent?.blocks?.map(({id, type, data}) => {
                                const Component = articleComponents[type];
                                if (!Component) {
                                    return undefined;
                                }

                                return <Component key={id} {...data} />;
                            })}
                        </article>
                    )}
                </Container>
            )}{" "}
            {form && (
                <DynamicForm
                    withBackground={haveAnyContent}
                    formDescription={form.formDescription}
                    title={form.title}
                    buttonText={form.buttonText}
                    fields={form.fields}
                />
            )}
        </Layout>
    );
};

export default CoursePageTemplate;
